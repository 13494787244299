import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/SEO"
import { seoData } from "../shared/seoData"
import MentoringOffering from "../components/Mentoring/MentoringOffering"
import { TeamMembersData } from "../shared/constants"
import { PageTitles } from "../shared/constants"

export default class FullStackJavaScript extends React.Component {
  render() {
    const seo = seoData.fullStackJS
    return (
      <>
        <SEO
          title={seo.title}
          description={seo.metaDescription}
          pathname={seo.pathname}
        />
        <Layout selectedTabValue={false}>
          <MentoringOffering title={PageTitles.fullStackJS.title}
                             instructors={TeamMembersData}
                             topics={topics}/>
        </Layout>
      </>
    )
  }
}

const topics = [
  "Basics of NodeJS",
  "Working with basic NodeJS APIs",
  "Working knowledge of ES6/ES7/ES8/ES9",
  "Adding 3rd party dependencies (ExpressJS)",
  "Understanding middlewares and how to use them",
  "Understanding of REST API Concepts",
  "Creating REST APIs for the project",
  "Working with Database (CRUD) using MongoDB",
  "Understanding promises vs. callbacks",
  "Understanding node vs. npm",
  "Basics of ReactJS",
  "Developing Web Pages using ReactJS & Material-UI for the project",
  "Working with the local deployment of the project",
  "Creating Production builds of the project & deployment to server"
]
